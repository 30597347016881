<template>
  <div>
    <div class="box-wraper" v-if="!show">
      <div class="box-head">
        <div class="t-c">
          <img class="wid285" src="@/imgs/comm/Slice13.png" alt="" />
        </div>
        <div class="t-c">
          <img class="wid243" src="@/imgs/comm/Slice14.png" alt="" />
        </div>
      </div>
      <div class="box-content">
        <van-image
          @load="loading = false"
          src="https://activity-resource.321mh.com/nft/blindbox/bindbox.gif"
        ></van-image>
      </div>
      <div class="t-c box-bottom">
        <div class="good-btn" @click="getCard">开盲盒</div>
        <!-- 点击任意位置开盲盒 -->
      </div>
      <!-- <van-image @load="loading = false" @click="getCard" :src="require('@/imgs/comm/box.gif')"></van-image> -->
    </div>
    <!-- <van-popup v-model:show="show" style=" overflow: visible n ;"> -->
    <div class="wraper">
      <div class="pop" :class="paused ? 'paused' : ''" v-if="show">
        <div class="card-bg">
          <div class="num">编号：{{ card.series_num }}</div>
          <div
            class="t-c"
            :class="card.items_name && card.items_name.length > 4 ? 'name-nobg' : 'name'"
          >
            <span class="span-name">{{ card.items_name }}</span>
          </div>
          <div class="t-c card-box">
            <img class="wid238" :src="card.image_icon" alt="" />
            <div class="t-c tag">
              <img :src="levelsChange(card.levels)" alt="" />
            </div>
            <!-- <van-image width="238"></van-image> -->
          </div>

          <div class="card-bt t-c">
            <span>{{ card.sub_name }}</span>
          </div>
        </div>
        <div class="t-c close" @click="close">
          <van-image width="44" :src="require('../imgs/comm/icon_gb.png')" />
        </div>
      </div>
    </div>
    <!-- </van-popup> -->
    <PageLoading :show="loading" />
  </div>
</template>

<script>
import { reactive, onMounted, toRefs, getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router';
import { checkorderispay, opencollection } from '@/service/home';
import { Toast } from 'vant';
import { nftUtils, localStorageUtil } from '@/utils';
import PageLoading from '@/components/loading/PageLoading.vue';
export default {
  components: {
    PageLoading
  },
  setup() {
    const router = useRouter();
    const { proxy } = getCurrentInstance();
    const route = proxy.$route;
    const state = reactive({
      img1: 'https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1652341341261.jpg',
      show: false,
      paused: false,
      orderNum: '2022051715091710',
      card: {},
      loading: true
    });
    onMounted(async () => {
      localStorageUtil.del('orderId');
      state.orderNum = route.params.id;
      const { data } = await checkorderispay({ trade_no: state.orderNum });
      // window.scroll(0, 0);
      if (data) {
        // 开盲盒
      } else {
        // 未支付
        Toast('该订单未支付');
        setTimeout(() => {
          router.push({ path: `/order`, replace: true });
        }, 1000);
      }
    });
    const getCard = async () => {
      const res = await opencollection({ trade_no: state.orderNum });
      state.card = res.data;
      state.show = !state.show;
      nftUtils.setcnzzRecordByBtn('开盲盒');
      setTimeout(() => {
        state.paused = true;
      }, 900);
    };
    const close = () => {
      nftUtils.setcnzzRecordByBtn('关闭卡片');
      router.push({ path: '/collections', replace: true });
    };
    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('../imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('../imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('../imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('../imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('../imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('../imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };
    return {
      ...toRefs(state),
      getCard,
      levelsChange,
      close
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.box-wraper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: 100vh;
  flex-grow: 1;
}
.good-btn {
  position: relative;
  display: flex;

  width: 272px;
  height: 45px;
  justify-content: center;
  align-items: center;
  background-image: url('../imgs/comm/pic_button_cmh.png');
  background-size: 100%;
  color: $primaryc;
}
.box-bottom {
  // padding-bottom: 61px;
  font-size: 16px;
  flex-grow: 1;
  margin: 0 auto;
}
.box-content {
  font-size: 0px;
  flex-grow: 2;
  img {
    width: 100%;
  }
}
.box-head {
  font-size: 0px;
  flex-grow: 1;
  padding-top: 55px;
}
.wid285 {
  width: 285px;
}
.wid243 {
  width: 243px;
  margin-top: 12px;
}
.wraper {
  //   position: absolute;
  // top: 40%;
  // left: 50%;
  transform: translate(0%, 0%);
  // text-align: center;
}
.pop {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  animation: mymove 1s infinite;
  -webkit-animation: mymove 1s infinite; /* Safari and Chrome */
  // transform: translate(0%, 0%);
}
.paused {
  animation-play-state: paused;
  transform: scale(1, 1) !important;
}
@keyframes mymove {
  0% {
    transform: scale(0, 0) rotateY(0deg);
  }
  100% {
    transform: scale(1, 1) rotateY(360deg);
  }
}

@-webkit-keyframes mymove {
  /* Safari and Chrome */
  0% {
    transform: scale(0, 0) rotateY(0deg);
  }
  100% {
    transform: scale(1, 1) rotateY(360deg);
  }
}
.van-popup {
}
.close {
  position: absolute;
  bottom: -66px;
  width: 100%;
}
.card-box {
  font-size: 0px;
  position: relative;
  margin-bottom: 14px;
}
.tag {
  position: absolute;
  bottom: 30px;
  margin: 0 auto;
  width: 100%;
  // margin: 14px 0 17px;
  font-size: 0px;
  img {
    width: 60px;
  }
}
.wid238 {
  width: 238px;
  font-size: 0px;
}
.num {
  line-height: 12px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
}
.card-bt {
  font-size: 16px;
  font-weight: bold;
  font-family: DFSongGB Std-W12, DFSongGB Std;
  background-image: -webkit-linear-gradient(bottom, #a78b5f, #ffeccd, #ffc466);

  -webkit-background-clip: text;

  -webkit-text-fill-color: transparent;
}
.t-c {
  text-align: center;
}
.name {
  margin: 10px auto;
  width: 236px;
  height: 34px;
  @include bis('../imgs/comm/Slice19.png');
  display: flex;
  // align-items: center;
  justify-content: center;
}
.name-nobg {
  margin: 10px auto;
  width: 236px;
  height: 34px;
  // @include bis("../imgs/comm/Slice19.png");
  display: flex;
  // align-items: center;
  justify-content: center;
}
.span-name {
  line-height: 20px;
  font-size: 20px;
  font-weight: bold;

  font-family: DFSongGB-W12;
  background-image: -webkit-linear-gradient(bottom, #a78b5f, #ffeccd, #ffc466);
  -webkit-background-clip: text;

  -webkit-text-fill-color: transparent;
}
.card-bg {
  @include bis('../imgs/comm/Slice17.png');
  width: 292px;
  //   height: 457px;
  box-sizing: border-box;
  padding: 13px 0 39px;
}
</style>
